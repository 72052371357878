let globalTranslations = null;
let globalEnums = null;

export default {
  methods: {
    async loadTranslations() {
      if (!globalTranslations) {
        const response = await this.$WebTranslations?.getCollection({ params: { pagination: false } });
        if (response && response.data) {
          globalTranslations = response.data["hydra:member"];
          return globalTranslations;
        }
      }
    },

    async getEnumTranslation(name, key, lang, data) {
      await this.loadTranslations();
      return new Promise((resolve) => {
        let result = { result: key, data };
        if (globalTranslations) {
          const translation = globalTranslations.find(
            (t) => t.type === name && t.language.toUpperCase() === lang.toUpperCase()
          );
          if (translation) {
            const t = translation.translations.find((t) => Object.prototype.hasOwnProperty.call(t, key));
            if (t && t[key].length) {
              result = { result: t[key], data };
            }
          }
        }
        resolve(result);
      });
    },

    async getEnumTranslationString(name, key) {
      const lang = this?.$i18n?.locale ? this.$i18n.locale : "sk";
      return new Promise((resolve) => {
        this.getEnumTranslation(name, key, lang).then((response) => {
          resolve(response.result);
        });
      });
    },

    async getEnumList(names, ...lists) {
      await this.loadTranslations();
      if (!(names instanceof Array)) {
        names = [names];
      }
      const promises = [];
      const lang = this?.$i18n?.locale ? this.$i18n.locale : "sk";

      if (!globalEnums) {
        const response = await this.$Enums.getCollection({ params: { pagination: false, cache: false } });
        if (response) {
          globalEnums = response.data["hydra:member"];
        }
      }

      return new Promise((resolve) => {
        names.forEach((name, index) => {
          for (const element of globalEnums) {
            if (name === element.name) {
              for (const key of element.keys) {
                promises.push(this.getEnumTranslation(name, key, lang, { index, key }));
              }
            }
          }
        });
        Promise.all(promises).then((results) => {
          results.forEach(({ result, data }) => {
            lists[data.index].push({
              label: result,
              id: data.key,
            });
          });
          resolve(lists);
        });
      });
    },

    getUserList(args, ...lists) {
      const { params } = args;
      params.pagination = false;
      if (params.role) {
        if (!(params.role instanceof Array)) {
          params.role = [params.role];
        }
      }
      return new Promise((resolve) => {
        this.$Users.getCollection({ params }, "user_list").then((response) => {
          response.data["hydra:member"].forEach((element) => {
            if (params.role) {
              params.role.forEach((role, index) => {
                if (role === element.role) {
                  lists[index].push({
                    label:
                      element.givenName && element.familyName
                        ? `${element.givenName} ${element.familyName} (ID: ${element.id})`
                        : `${element.email} (ID: ${element.id})`,
                    id: element["@id"],
                    user: element,
                  });
                }
              });
            } else {
              lists[0].push({
                label:
                  element.givenName && element.familyName
                    ? `${element.givenName} ${element.familyName}`
                    : element.email,
                id: element["@id"],
                user: element,
              });
            }
          });
          resolve(lists);
        });
      });
    },

    getList(resource, list, serializationGroup, exclude = null, search = null, labelKey = "name", idKey = "@id") {
      return new Promise((resolve) => {
        const params = {
          pagination: false,
        };
        if (search) {
          const s = search.toLowerCase();
          if (s.startsWith("id:")) {
            if (s.length > 3) {
              params.id = s.replaceAll("id:", "");
            }
          } else {
            params.search = search;
          }
        }
        resource.getCollection({ params }, serializationGroup).then((response) => {
          response.data["hydra:member"].forEach((element) => {
            if (exclude !== element.id) {
              list.push({
                label: `${element[labelKey]} (ID: ${element.id})`,
                id: element[idKey],
                object: element,
              });
            }
          });
          resolve(list);
        });
      });
    },

    getPagesList(list, exclude = null) {
      return this.getList(this.$WebPages, list, "id_name", exclude);
    },

    getArticlesList(list, exclude = null) {
      return this.getList(this.$Articles, list, "id_name", exclude);
    },

    getArticleCategoriesList(list, exclude = null) {
      return new Promise((resolve) => {
        const params = {
          pagination: false,
          type: "ARTICLE",
        };
        this.$Categories.getCollection({ params }, "id_name").then((response) => {
          response.data["hydra:member"].forEach((element) => {
            if (exclude !== element.id) {
              list.push({
                label: `${element.name} (ID: ${element.id})`,
                id: element["@id"],
              });
            }
          });
          resolve(list);
        });
      });
    },

    getGoogleProductCategoriesList(list) {
      return new Promise((resolve) => {
        const params = { pagination: false, cache: true, ttl: 604800 };
        this.$GoogleProductCategories.getCollection({ params }, "id_name").then((response) => {
          response.data["hydra:member"].forEach((element) => {
            list.push({
              label: `${element.name} (ID: ${element.id})`,
              id: element.id,
            });
          });
          resolve(list);
        });
      });
    },

    getProducersList(list, exclude = null) {
      return this.getList(this.$Producers, list, "id_name", exclude);
    },

    getBannerSlidesList(list) {
      return this.getList(this.$BannerSlides, list, "id_name");
    },

    getParametersList(list) {
      return new Promise((resolve) => {
        const params = {
          pagination: false,
        };
        this.$Parameters.getCollection({ params }).then((response) => {
          response.data["hydra:member"].forEach((element) => {
            const items = [];
            element.items.forEach((i) => {
              items.push({
                id: i.value,
                label: i.name,
                parameterItemIri: i["@id"],
                parameterIri: element["@id"],
                // parameter: element,
              });
            });

            list.push({
              label: `${element.name} (ID: ${element.id})`,
              id: element["@id"],
              type: element.type,
              url: element.url,
              items,
            });
          });
          resolve(list);
        });
      });
    },

    getConfigurationsList(list) {
      return this.getList(this.$Configurations, list, "id_name");
    },

    getProductsList(list, exclude = null, serializationGroup = "id_name") {
      return this.getList(this.$Products, list, serializationGroup, exclude);
    },

    getVariantsList(list) {
      return this.getList(this.$ProductVariants, list, "id_name");
    },

    getCustomResourcesList(list, exclude = null) {
      return this.getList(this.$CustomResources, list, "id_name", exclude);
    },

    getReviewsList(list, exclude = null) {
      return this.getList(this.$Reviews, list, "id_name", exclude);
    },

    getProductVariantsList(list, product) {
      return new Promise((resolve) => {
        const params = {
          pagination: false,
        };
        this.$Products.getResourceByUrl({ url: `${product}/variants`, params }, "id_name").then((response) => {
          response.data["hydra:member"].forEach((element) => {
            list.push({
              label: `${element.name} (ID: ${element.id})`,
              id: element["@id"],
            });
          });
          resolve(list);
        });
      });
    },

    getCarriersList(list) {
      return this.getList(this.$Carriers, list, ["id", "name", "serviceType"]);
    },

    getPaymentsList(list) {
      return this.getList(this.$Payments, list, "id_name");
    },

    getPickUpPointsList(list) {
      return new Promise((resolve) => {
        const params = {
          pagination: false,
        };
        this.$PickUpPoints.getCollection({ params }).then((response) => {
          response.data["hydra:member"].forEach((element) => {
            list.push({
              label: `${element.name} (ID: ${element.id})`,
              id: element["@id"],
              pickUpPoint: element,
            });
          });
          resolve(list);
        });
      });
    },

    getPriceLevelsList(list) {
      return new Promise((resolve) => {
        const params = {
          pagination: false,
        };
        this.$PriceLevels.getCollection({ params }).then((response) => {
          response.data["hydra:member"].forEach((element) => {
            list.push(element);
          });
          resolve(list);
        });
      });
    },

    getZonesList(list) {
      return this.getList(this.$Zones, list, "id_name");
    },

    getShopsList(list) {
      return this.getList(this.$Shops, list, ["id", "name", "default", "priceDefinition"]);
    },

    getDiscountsList(list) {
      return this.getList(this.$Discounts, list, "id_name");
    },

    getEstablishmentsList(list, exclude = null) {
      return new Promise((resolve) => {
        const params = {
          pagination: false,
        };
        this.$Establishments
          .getCollection({ params }, ["id", "name", "companyName", "ico", "dic", "icdph", "billingAddress"])
          .then((response) => {
            response.data["hydra:member"].forEach((element) => {
              if (exclude !== element.id) {
                let label = element.companyName;
                label = element.name ? `${element.name} (${element.companyName})` : element.companyName;
                list.push({
                  label,
                  id: element["@id"],
                  object: element,
                });
              }
            });
            resolve(list);
          });
      });
    },

    getGroupsList(list) {
      return this.getList(this.$UserGroups, list, "id_name");
    },

    getRolesList(list) {
      return this.getList(this.$Roles, list, "id_name");
    },

    async getDefaultShopTaxes(taxes) {
      let taxesEnum = [];
      await this.getEnumList("TAX", taxesEnum);
      return new Promise((resolve) => {
        const params = {
          default: true,
        };
        this.$Shops.getCollection({ params }, ["zeroTax", "lowTax", "highTax"]).then((response) => {
          if (response.status === 200 && response.data["hydra:member"].length) {
            const defaultShop = response.data["hydra:member"][0];
            for (const tax of taxesEnum) {
              const t = JSON.parse(JSON.stringify(tax));
              if (t.id == "ZERO") {
                t.value = defaultShop.zeroTax;
              } else if (t.id == "LOW") {
                t.value = defaultShop.lowTax;
              } else if (t.id == "HIGH") {
                t.value = defaultShop.highTax;
              }
              taxes.push(t);
            }
            resolve(taxes);
          }
        });
      });
    },

    async getPriceDefinitionsList(list) {
      const currencies = [];
      await this.getEnumList("CURRENCY", currencies);
      return new Promise((resolve) => {
        const params = {
          pagination: false,
        };
        this.$PriceDefinitions.getCollection({ params }).then((response) => {
          response.data["hydra:member"].forEach((element) => {
            if (element.currency) {
              const existingCurrencyp = currencies.find((u) => u.id === element.currency);
              element.currency = existingCurrencyp;
            }
            list.push(element);
          });
          resolve(list);
        });
      });
    },

    getResourceOneToManyRelationList(
      list,
      resource = this.$Products,
      item,
      relation = "variants",
      serializationGroup = "id_name",
      original = false
    ) {
      return new Promise((resolve) => {
        const params = {
          pagination: false,
        };
        resource
          .getResourceByUrl({ url: `${item}/${relation}`, params }, serializationGroup)
          .then((response) => {
            response.data["hydra:member"].forEach((element) => {
              if (original) {
                list.push(element);
              } else {
                list.push({
                  label: `${element.name} (ID: ${element.id})`,
                  id: element["@id"],
                });
              }
            });
            resolve(list);
          })
          .catch(() => {
            resolve(list);
          });
      });
    },

    async getTagsList(list, resource = "ARTICLE") {
      return new Promise((resolve) => {
        this.$Tags.getResourceByUrl({ url: `/tags/${resource}` }).then((response) => {
          response.data.tags.forEach((element) => {
            list.push({
              label: element,
              id: element,
            });
          });
          resolve(list);
        });
      });
    },

    getPublishList(list) {
      return new Promise((resolve) => {
        list.push({
          label: this.$t("forms.published"),
          id: true,
        });
        list.push({
          label: this.$t("forms.draft"),
          id: false,
        });
        resolve(list);
      });
    },
  },
};
